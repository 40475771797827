<div class="banner" fxLayoutAlign="center center">
  <h1 class="title text-white" style="border-bottom: 2px solid whitesmoke;">Privacy Policy</h1>
</div>
<div class="div-pad" fxLayout="column">
  <p class="sub-title sub-title-mar10"> 360FMS is committed to protecting the privacy of our website visitors and our
    app users. We protect your Personal Information that you have shared with us.</p>
  <h2 class="title div-sub-pad">Why does 360FMS collect Personal Information?</h2>
  <p class="sub-title sub-title-mar10">We collect information is to provide customized services and communicate with
    customers.</p>
  <h2 class="title div-sub-pad">We use your Personal Information for the following :</h2>
  <li class="sub-title sub-title-mar10">Conduct basic business operations, such as communicate with customers</li>
  <li class="sub-title sub-title-mar10">Provide requested information or services</li>
  <li class="sub-title sub-title-mar10">Respond to your request or further process your submitted form; Improve our
    services, Websites and Apps</li>
  <li class="sub-title sub-title-mar10">Respond to a legitimate legal request from law enforcement authorities or other
    government regulators</li>
  <li class="sub-title sub-title-mar10">Obtain your location data in order to provide requested information or services;
    or</li>
  <li class="sub-title sub-title-mar10">Develop new offerings, improve the quality of our products, improve and
    personalize user experience, and better prepare future content
    based upon your interests and those of our general user population.</li>

  <h2 class="title div-sub-pad">Types of Data Collected</h2>
  <h2 class="title div-sub-pad">Personal Data</h2>
  <p class="sub-title sub-title-mar10">While using our Service, we may ask you to provide us with certain personally
    identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable
    information may include, but is not limited to:</p>
  <li class="sub-title sub-title-mar10">Email address</li>
  <li class="sub-title sub-title-mar10">First name and last name</li>
  <li class="sub-title sub-title-mar10">Phone number</li>
  <li class="sub-title sub-title-mar10">Address, State, Province, ZIP/Postal code, City</li>
  <li class="sub-title sub-title-mar10">Cookies and Usage Data</li>
  <h2 class="title div-sub-pad">Usage Data</h2>
  <p class="sub-title sub-title-mar10">We may also collect information how the Service is accessed and used ("Usage
    Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address),
    browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time
    spent on those pages, unique device identifiers and other diagnostic data.</p>
  <h2 class="title div-sub-pad">Data created during use of our services </h2>
  <p class="sub-title sub-title-mar10">We capture location data from technician app so that maintenance supervisors
    managing 2 or 3 sites can allocate the ticket to the nearest technician based on his current location. </p>
  <p class="sub-title sub-title-mar10">We also capture location data from Technician app, so that within a site,
    technicians can communicate their exact location to Site Managers and fellow technicians. This makes communication
    and task allocation easy.</p>
  <h2 class="title div-sub-pad">Tracking & Cookies Data</h2>
  <p class="sub-title sub-title-mar10">We use cookies and similar tracking technologies to track the activity on our
    Service and hold certain information.</p>
  <p class="sub-title sub-title-mar10">Cookies are files with small amount of data which may include an anonymous unique
    identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also
    used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
  <p class="sub-title sub-title-mar10">You can instruct your browser to refuse all cookies or to indicate when a cookie
    is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
  <h2 class="title div-sub-pad">Examples of Cookies we use:</h2>
  <li class="sub-title sub-title-mar10">Session Cookies: We use Session Cookies to operate our Service.</li>
  <li class="sub-title sub-title-mar10">Preference Cookies: We use Preference Cookies to remember your preferences and
    various settings.</li>
  <li class="sub-title sub-title-mar10">Security Cookies: We use Security Cookies for security purposes.</li>
  <h2 class="title div-sub-pad">Use of Data</h2>
  <p class="sub-title sub-title-mar10">360 fms uses the collected data for various purposes:</p>
  <li class="sub-title sub-title-mar10">To provide and maintain the Service</li>
  <li class="sub-title sub-title-mar10">To notify you about changes to our Service</li>
  <li class="sub-title sub-title-mar10">To allow you to participate in interactive features of our Service when you
    choose to do so</li>
  <li class="sub-title sub-title-mar10">To provide customer care and support</li>
  <li class="sub-title sub-title-mar10">To provide analysis or valuable information so that we can improve the Service
  </li>
  <li class="sub-title sub-title-mar10">To monitor the usage of the Service</li>
  <li class="sub-title sub-title-mar10">To detect, prevent and address technical issues</li>
  <h2 class="title div-sub-pad">Transfer Of Data</h2>
  <p class="sub-title sub-title-mar10">Your information, including Personal Data, may be transferred to — and maintained
    on — computers located outside of your state, province, country or other governmental jurisdiction where the data
    protection laws may differ than those from your jurisdiction.</p>
  <p class="sub-title sub-title-mar10">If you are located outside India and choose to provide information to us, please
    note that we transfer the data, including Personal Data, to India and process it there.</p>
  <p class="sub-title sub-title-mar10">Your consent to this Privacy Policy followed by your submission of such
    information represents your agreement to that transfer.</p>
  <p class="sub-title sub-title-mar10">360 fms will take all steps reasonably necessary to ensure that your data is
    treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to
    an organization or a country unless there are adequate controls in place including the security of your data and
    other personal information.</p>
  <h2 class="title div-sub-pad">Disclosure Of Data</h2>
  <h2 class="title div-sub-pad">Legal Requirements</h2>
  <p class="sub-title sub-title-mar10">360 fms may disclose your Personal Data in the good faith belief that such action
    is necessary to:</p>
  <li class="sub-title sub-title-mar10">To comply with a legal obligation</li>
  <li class="sub-title sub-title-mar10">To protect and defend the rights or property of 360 fms</li>
  <li class="sub-title sub-title-mar10">To prevent or investigate possible wrongdoing in connection with the Service
  </li>
  <li class="sub-title sub-title-mar10">To protect the personal safety of users of the Service or the public</li>
  <li class="sub-title sub-title-mar10">To protect against legal liability</li>
  <h2 class="title div-sub-pad">Security Of Data</h2>
  <p class="sub-title sub-title-mar10">The security of your data is important to us, but remember that no method of
    transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
    acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
  <h2 class="title div-sub-pad">Service Providers</h2>
  <p class="sub-title sub-title-mar10">We may employ third party companies and individuals to facilitate our Service
    ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in
    analyzing how our Service is used.</p>
  <p class="sub-title sub-title-mar10">These third parties have access to your Personal Data only to perform these tasks
    on our behalf and are obligated not to disclose or use it for any other purpose.</p>
  <h2 class="title div-sub-pad">Analytics</h2>
  <p class="sub-title sub-title-mar10">We may use third-party Service Providers to monitor and analyze the use of our
    Service.</p>
  <h2 class="title div-sub-pad">Google Analytics</h2>
  <p class="sub-title sub-title-mar10">Google Analytics is a web analytics service offered by Google that tracks and
    reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is
    shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its
    own advertising network.</p>
  <p class="sub-title sub-title-mar10">You can opt-out of having made your activity on the Service available to Google
    Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics
    JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.
  </p>
  <p class="sub-title sub-title-mar10">For more information on the privacy practices of Google, please visit the Google
    Privacy & Terms web page: https://policies.google.com/privacy?hl=en</p>
  <h2 class="title div-sub-pad">Links To Other Sites</h2>
  <p class="sub-title sub-title-mar10">Our Service may contain links to other sites that are not operated by us. If you
    click on a third party link, you will be directed to that third party's site. We strongly advise you to review the
    Privacy Policy of every site you visit.</p>
  <p class="sub-title sub-title-mar10">We have no control over and assume no responsibility for the content, privacy
    policies or practices of any third party sites or services.</p>
  <h2 class="title div-sub-pad">Children's Privacy</h2>
  <p class="sub-title sub-title-mar10">Our Service does not address anyone under the age of 18 ("Children").</p>
  <p class="sub-title sub-title-mar10">We do not knowingly collect personally identifiable information from anyone under
    the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal
    Data, please contact us. If we become aware that we have collected Personal Data from children without verification
    of parental consent, we take steps to remove that information from our servers.</p>

  <h2 class="title div-sub-pad">Changes To This Privacy Policy</h2>
  <p class="sub-title sub-title-mar10">We may update our Privacy Policy from time to time. We will notify you of any
    changes by posting the new Privacy Policy on this page.</p>
  <p class="sub-title sub-title-mar10">We will let you know via email and/or a prominent notice on our Service, prior to
    the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
  <p class="sub-title sub-title-mar10">You are advised to review this Privacy Policy periodically for any changes.
    Changes to this Privacy Policy are effective when they are posted on this page.</p>
  <h2 class="title div-sub-pad">Contact Us</h2>
  <p class="sub-title sub-title-mar10 ">If you have any questions about this Privacy Policy, please contact us:</p>
  <p class="sub-title sub-title-mar10 div-sub-pad">By email: mavkdevelopment@gmail.com</p>
</div>