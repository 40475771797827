import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {
  miniMode = false;
  profileUrl: string;
  isAuthenticated = false;
  imagePath = '';
  constructor(private accountService: AuthService) { }
  userName = '';
  ngOnInit(): void {
    this.userName = localStorage.getItem('name');
  }

  get menuItems(): MenuItem[] {
    if (this.accountService.isAdminLogin) {
      return adminMenus;
    } else if (this.accountService.isClientLogin) {
      return clientMenus;
    } else {
      return supervisorMenus;
    }
  }
}
export interface MenuItem {
  icon?: string;
  title?: string;
  link?: string;
  items?: MenuItem[];
  isExpandable?: boolean;
}

export const adminMenus: MenuItem[] = [
  { icon: 'dashboard', title: 'Dashboard', link: 'admin/home/dashboard', isExpandable: false, items: [] },
  { icon: 'assignment', title: 'Tickets', link: 'admin/home/tickets', isExpandable: false, items: [] },
  { icon: 'person', title: 'O & M', link: 'admin/technician/list', isExpandable: false, items: [] },
  { icon: 'local_library', title: 'Companies', link: 'admin/companies/list', isExpandable: false, items: [] },
  { icon: 'gamepad', title: 'Spares', link: 'admin/products/product-list', isExpandable: false, items: [] },
  { icon: 'gamepad', title: 'Spares Request', link: 'admin/home/spares-request', isExpandable: false, items: [] },
  { icon: 'assignment', title: 'Disputed Tickets', link: 'admin/home/disputed-tckets', isExpandable: false, items: [] },
  { icon: 'add_task', title: 'Leave', link: 'admin/home/notifications', isExpandable: false, items: [] },
  {
    icon: 'announcement', title: 'Report', link: 'admin/home', isExpandable: true, items: [
      { icon: 'analytics', title: 'Assets Resolution', link: 'admin/home/assets', isExpandable: false, items: [] },
      { icon: 'sync', title: 'Technician Response Time', link: 'admin/home/technician', isExpandable: false, items: [] },
      { icon: 'swap_vert', title: 'Up And Down Time ', link: 'admin/home/uptime', isExpandable: false, items: [] },
    ]
  },
  { icon: 'assignment', title: 'Manage SuperVisor', link: 'admin/manage-supervisor', isExpandable: false, items: [] },
];

export const clientMenus: MenuItem[] = [
  { icon: 'dashboard', title: 'Dashboard', link: 'client/dashboard', isExpandable: false, items: [] },
  { icon: 'person', title: 'Profile', link: 'client/profile', isExpandable: false, items: [] },
  { icon: 'assignment', title: 'Tickets', link: 'client/tickets', isExpandable: false, items: [] },
  { icon: 'build', title: 'PM Service', link: 'client/pm-services', isExpandable: false, items: [] },
  { icon: 'gamepad', title: 'Spares', link: 'client/spares', isExpandable: false, items: [] },
  { icon: 'assessment', title: 'Assets', link: 'client/assets', isExpandable: false, items: [] },
];
export const supervisorMenus: MenuItem[] = [
  // { icon: 'assignment', title: 'Manage SuperVisor', link: 'supervisor/supervisor', isExpandable: false, items: [] },
  { icon: 'local_library', title: 'Companies', link: 'supervisor/companies/list', isExpandable: false, items: [] },
  // { icon: 'assignment', title: 'Tickets', link: 'supervisor/tickets', isExpandable: false, items: [] },
  // { icon: 'assessment', title: 'Assets', link: 'supervisor/assets', isExpandable: false, items: [] },
];
