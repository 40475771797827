import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { Configuration, ConfigurationParameters, ApiModule } from 'build/openapi';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ThemeService } from 'ng2-charts';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppThemeModule } from './app-theme.module';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './_interceptors/jwt.interceptors';
import { LandingPagesModule } from './_modules/landing-pages/landing-pages.module';
import { MainModule } from './_modules/main/main.module';
import { SafePipe } from './_pipes/safe-pipe.pipe';


export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.basePath,
    apiKeys: { Bearer: '' }
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppThemeModule,
    MainModule,
    ApiModule.forRoot(apiConfigFactory),
    HttpClientModule,
    LoadingBarHttpClientModule,
    RouterModule,
    NgApexchartsModule,
    LandingPagesModule,
    AppThemeModule
  ],
  providers: [DatePipe, ThemeService, {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
