import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppThemeModule } from 'src/app/app-theme.module';
import { HomeComponent } from './home/home.component';
import { LandingPagesRoutingModule } from './landing-pages-routing.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';


@NgModule({
  declarations: [
    HomeComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent
  ],
  imports: [
    CommonModule,
    LandingPagesRoutingModule,
    AppThemeModule,
    FlexLayoutModule
  ],
  exports: [HomeComponent]
})
export class LandingPagesModule { }
