import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class SupervisorAuthGuard implements CanActivate {

    constructor(private router: Router, private accountService: AuthService) { }

    canActivate(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.accountService.isSupervisorLogin) {
            return true;
        } else if (this.accountService.isAdminLogin || this.accountService.isClientLogin) {
            this.router.navigate(['/not-found']);
            return false;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/sign-in'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
