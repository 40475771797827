import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HomeService } from 'build/openapi';
import { PopupSuccessComponent } from '../../shared/popup-success/popup-success.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  contactForm: FormGroup;
  errorMessage: string;
  showSpinner: boolean;
  constructor(private fb: FormBuilder, private router: Router, private homeService: HomeService, private dialog: MatDialog) { }

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[6-9]{1}[0-9]{9}$')]],
      message: ['', Validators.required],
    });
    this.contactForm.valueChanges.subscribe(val => {
      if (val) {
        if (this.contactForm.valid) {
          this.errorMessage = '';
        }
      }
    })
  }
  onSubmit() {
    if (this.contactForm.valid) {
      this.showSpinner = true;
      this.homeService.apiHomeContactUsPost(this.contactForm.value).subscribe(res => {
        this.showSpinner = false;
        if (res.code == 1) {
          this.contactForm.reset();
          const timeout = 2000;
          const dialogRef = this.dialog.open(PopupSuccessComponent, {
            width: '300px',
            data: res.message
          });
          dialogRef.afterOpened().subscribe(_ => {
            setTimeout(() => {
              dialogRef.close();
            }, timeout)
          })
        }
        else {
          this.errorMessage = res.message;
        }
      }, (error: HttpErrorResponse) => {
        this.showSpinner = false;
        this.errorMessage = error.message;
      })
    }
    else {
      this.errorMessage = "Please enter all required fields";
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  activateRoute(id:number){
    if(id == 1){
      this.router.navigate(["/home"]);
      document.getElementById('home').scrollIntoView({ behavior: 'smooth' });
    }
    else if(id == 2){
      this.router.navigate(["/home"]);
      document.getElementById('aboutus').scrollIntoView({ behavior: 'smooth' });
    }
    else if(id == 3){
      this.router.navigate(["/home"]);
      document.getElementById('ourTeam').scrollIntoView({ behavior: 'smooth' });
    }
    else if(id == 4){
      this.router.navigate(["/home"]);
      document.getElementById('contactUs').scrollIntoView({ behavior: 'smooth' });
    }
  }
}
