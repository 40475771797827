import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppThemeModule } from 'src/app/app-theme.module';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';



@NgModule({
  declarations: [NavigationComponent, SideNavigationComponent, FooterComponent],
  imports: [
    CommonModule,
    AppThemeModule,
    RouterModule,
  ],
  exports: [SideNavigationComponent, FooterComponent, NavigationComponent]
})
export class MainModule { }
