<mat-toolbar fxLayout="row nowrap" class="mat-elevation-z3">
    <mat-toolbar-row fxHide fxShow.gt-sm fxLayout="row nowrap" fxLayoutAlign="space-between center" fxLayoutGap="20px"
        fxLayoutGap.lt-sm="0px">
        <span>
            <button mat-icon-button (click)="goBack()">
                <mat-icon style="color:white">arrow_back</mat-icon>
            </button>
        </span>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <button mat-icon-button (click)="notificationRoute()"  >
                <mat-icon style="color:white">notifications</mat-icon>
            </button>
            <button mat-flat-button style="background-color: transparent;">
                <img [matMenuTriggerFor]="avatarMenu" src="../../../../assets/images/M.png" class="avatar">
            </button>
            <mat-menu #avatarMenu="matMenu">
                <button mat-menu-item>
                    <mat-icon class="mat-18">person</mat-icon>
                    <span>{{userName}}</span>
                </button>
                <button mat-menu-item (click)="logOut()">
                    <mat-icon class="mat-18">logout</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row fxHide fxShow.lt-md fxLayout="row nowrap" fxLayoutAlign="space-between center" fxLayoutGap="20px"
        fxLayoutGap.lt-sm="0px">
        <div fxLayout="row">
            <button mat-icon-button (click)="toggle.emit()">
                <mat-icon class="mat-white">menu</mat-icon>
            </button>
            <span>
                <h2 style="color:white;letter-spacing: 1px;font-weight: 400;">360FMS</h2>
            </span>
        </div>
        <div fxLayout="row">
            <button mat-flat-button style="background-color: transparent;">
                <img [matMenuTriggerFor]="avatarMenu" src="../../../../assets/images/M.png" class="avatar">
            </button>
            <mat-menu #avatarMenu="matMenu">
                <button mat-menu-item>
                    <mat-icon class="mat-18">person</mat-icon>
                    <span>{{userName}}</span>
                </button>
                <button mat-menu-item (click)="logOut()">
                    <mat-icon class="mat-18">lock</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>
        </div>
    </mat-toolbar-row>
</mat-toolbar>