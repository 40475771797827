import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-success',
  templateUrl: './popup-success.component.html',
  styleUrls: ['./popup-success.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupSuccessComponent implements OnInit {
  Result: string;
  constructor(
    public dialogRef: MatDialogRef<PopupSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.Result = this.data;
  }
}
