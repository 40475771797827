import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Configuration } from 'build/openapi';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { slideInAnimation } from './_animations/app.animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements AfterViewInit {
  @ViewChild('drawer') drawer: MatDrawer;
  scrolled = false;
  trigger = 0;
  darkTheme = false;
  specialPages: string[] = [
    '/account/sign-in',
    '/account/forgot-password',
    '/account/docs',
    '/account/reset-password',
  ];
  specialPage = true;
  landingPages: string[] = [
    '/home',
    '/privacy-policy',
    '/terms-conditions'
  ];
  landingPage = true;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  routeName = 'home';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private configuration: Configuration
  ) {
    this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        const url = data?.urlAfterRedirects?.split('?')[0];
        try {
          if (this.specialPages?.findIndex(x => x === url) > -1 && this.landingPages?.findIndex(x => x === url) > -1) {
            this.specialPage = true;
            this.landingPage = true;
          } else if (this.specialPages?.findIndex(x => x === url) > -1) {
            this.specialPage = true;
            this.landingPage = false;
          } else if (this.landingPages?.findIndex(x => x === url) > -1) {
            this.specialPage = false;
            this.landingPage = true;
          } else {
            this.specialPage = false;
            this.landingPage = false;
          }
        } catch (error) {
          this.specialPage = true;
          this.landingPage = true;
        }
      }
    });
  }

  toggleTheme() {
    if (localStorage.getItem('darkTheme')) {
      localStorage.removeItem('darkTheme');
      this.darkTheme = false;
    } else {
      localStorage.setItem('darkTheme', 'darkTheme');
      this.darkTheme = true;
    }
    this.setTheme();
  }

  toggleUrl() {
    if (this.configuration.basePath === environment.basePath) {
      this.configuration.basePath = 'http://localhost/MavacApi';
    } else {
      this.configuration.basePath = environment.basePath;
    }
  }

  activateRoute(id: number) {
    if (id == 1) {
      this.router.navigate(['/home']);
      document.getElementById('home').scrollIntoView({ behavior: 'smooth' });
    } else if (id == 2) {
      this.router.navigate(['/home']);
      document.getElementById('aboutus').scrollIntoView({ behavior: 'smooth' });
    } else if (id == 3) {
      this.router.navigate(['/home']);
      document.getElementById('ourTeam').scrollIntoView({ behavior: 'smooth' });
    } else if (id == 4) {
      this.router.navigate(['/home']);
      document.getElementById('contactUs').scrollIntoView({ behavior: 'smooth' });
    }
  }

  setTheme(): void {
    if (this.darkTheme && !this.specialPage) {
      document.getElementById('body').className = 'theme-alternate';
    } else {
      document.getElementById('body').className = '';
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (localStorage.getItem('darkTheme')) {
        this.darkTheme = true;
      }
      this.setTheme();
    }, 0);
  }

  mouseEnter(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (!trigger.menuOpen) {
        trigger.openMenu();
      }
      this.trigger = 0;
    }, 1);
  }

  mouseLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (trigger.menuOpen && this.trigger === 0) {
        trigger.closeMenu();
      }
    }, 200);
  }

  menuMouseLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (trigger.menuOpen) {
        trigger.closeMenu();
      }
      this.trigger = 0;
    }, 1);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
