<div class="footer">
  <div class="div-pad " fxLayout="row wrap" fxLayoutGap="40px" fxLayoutGap.lt-md="0px">
    <div fxFlex="calc(30% - 40px)" fxFlex.lt-md="calc(100% - 0px)" fxLayout="column" class="sub-div">
      <img src="../../../../assets/images/white-logo.png" width="120px">
      <h3 class="title">About Us</h3>
      <span class="sub-title">360FMS is an industry leader in HAVC maintenance services
        and has hands-on experience in delivering HAVC projects</span>
    </div>
    <div fxFlex="calc(45% - 40px)" fxFlex.lt-md="calc(100% - 0px)" fxLayout="row wrap" fxLayoutGap="40px"
      fxLayoutGap.lt-sm="0px">
      <div fxFlex="calc(50% - 40px)" fxFlex.lt-sm="calc(100% - 0px)" fxLayout="column" class="sub-div">
        <h3 class="title">Our Services</h3>
        <p class="sub-title sub-title-mar10 link">Business to Business</p>
        <p class="sub-title sub-title-mar10 link">Business to Customers</p>
        <p class="sub-title sub-title-mar10 link">Direct to Business</p>
      </div>
      <div fxFlex="calc(50% - 40px)" fxFlex.lt-sm="calc(100% - 0px)" fxLayout="column" class="sub-div">
        <h3 class="title">Additional Pages</h3>
        <p class="sub-title sub-title-mar10 link" (click)="activateRoute(1)">Home</p>
        <p class="sub-title sub-title-mar10 link" (click)="activateRoute(2)">About Us</p>
        <p class="sub-title sub-title-mar10 link" (click)="activateRoute(3)">Our Team</p>
        <p class="sub-title sub-title-mar10 link" (click)="activateRoute(4)">Contact Us</p>
      </div>
    </div>
    <div fxFlex="calc(25% - 40px)" fxFlex.lt-md="calc(100% - 0px)" class="sub-div-footer sub-div">
      <h3 class="title">Contact Us</h3>
      <div fxLayout="row wrap" fxLayoutGap.lt-md="10px" class="footer-form" [formGroup]="contactForm">
        <mat-form-field appearance="none" fxFlex="100%" class="accounts1">
          <input matInput placeholder="Name" formControlName="name">
          <mat-error *ngIf="contactForm.get('name').hasError('required')">
            Name required
          </mat-error>
        </mat-form-field>
       
        <mat-form-field appearance="none" fxFlex="100%" class="accounts1">
          <input matInput placeholder="Email ID" formControlName="email" type="text">
          <mat-error *ngIf="contactForm.get('email').hasError('required')">
            Email required
          </mat-error>
          <mat-error
            *ngIf="!contactForm.get('email').hasError('required') && contactForm.get('email').hasError('email')">
            Enter valid email
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="100%" class="accounts1">
          <input matInput placeholder="Number" formControlName="phoneNumber" (keypress)="numberOnly($event)" maxlength="10" minlength="10">
          <mat-error *ngIf="contactForm.get('phoneNumber').hasError('required')">
            PhoneNumber required
          </mat-error>
          <mat-error
            *ngIf="!contactForm.get('phoneNumber').hasError('required') && contactForm.get('phoneNumber').hasError('pattern')">
            Enter valid phoneNumber
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="100%" class="accounts1">
          <textarea matInput rows="3" placeholder="Message" formControlName="message"></textarea>
          <mat-error *ngIf="contactForm.get('message').hasError('required')">
            message required
          </mat-error>
        </mat-form-field>
        <div></div>
      </div>
      <p style="margin:0px;color:red" *ngIf="errorMessage">{{errorMessage}}</p>

      <div fxLayout="row">
        <button mat-raised-button class="btn-border-radius" [disabled]="showSpinner" fxLayout="row nowrap"
        fxLayoutAlign="center center" fxLayoutGap="10px" style="color:whitesmoke; background-color: #fa8703;"
        (click)="onSubmit()">
        <div fxFlex="40px" *ngIf="showSpinner" fxLayoutAlign="center center">
          <mat-spinner></mat-spinner>
        </div>
        <span>{{showSpinner == true ?'Sending':'Send Message'}}</span>
      </button>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-sm="center center" class="footer-bottom">
    <div fxLayout="row wrap" fxLayoutGap="20px">
      <a class="sub-title sub-title-mar5 link" style="color:white;text-decoration:none" href="http://360fms.com/privacy-policy">Privacy Policy</a>
      <span class="sub-title sub-title-mar5 link" routerLink="/terms-conditions">Terms & Conditions</span>

    </div>
    <span class="sub-title sub-title-mar5">Copyright <i class="material-icons">copyright</i> 2020 360FMS Ltd</span>
  </div>
</div>