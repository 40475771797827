export const constant = {
    roles: {
        ROLE_ADMIN: 'superadmin',
        ROLE_CLIENT: 'client',
        ROLE_SUPERVISOR: 'technician',
        ROLE_SUPERVISORS: 'supervisor',
    },
    authData: {
        access_token: 'access_token',
        refresh_token: 'refresh_token'
    },
    mainAccount: {
        photo_path: 'photo_path',
        account_name: 'account_name',
        account_gender: 'account_gender'
    }
  }
  