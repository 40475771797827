<div class="banner" fxLayout="row wrap" fxLayout.lt-sm="column-reverse" fxLayoutAlign="space-between none" id="home">
  <div fxFlex="" fxFlex.lt-sm="100%" fxLayout="column" style="padding:5.20vw 0px" >
    <h1 class="title text-white ">Manage and Maintain your
      infrastructure better with 360FMS</h1>
    <p class="sub-title sub-title-mar10 text-white ">The revolutionary
      technology for building management</p>
    <div>
      <button mat-stroked-button
        style="padding:3px 30px;border:2px solid white; color:white;letter-spacing: 1px;border-radius:30px"
        (click)="activateRoute()">Contact
        Us</button>
    </div>
  </div>
  <div fxFlex="" fxFlex.lt-sm="100%" fxLayoutAlign="end start" >
    <img src="../../../../assets/images/banner-image.png" width="100%">
  </div>
</div>
<div class="div-pad" fxLayout="column">
  <div fxLayout="column" fxLayoutAlign="center center" >
    <h1 class="title text-primary">Features</h1>
    <p class="sub-title sub-title-mar10 text-accent text-align">A futures
      contract allows a trader to speculate on the direction of movement of commodity's price</p>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="40px" fxLayoutGap.lt-sm="0px" class="div-sub-pad">

    <a fxFlex="calc(33% - 40px)" fxFlex.lt-md="calc(50% - 40px)" fxFlex.lt-sm="calc(100% - 0px)" fxLayout="column"
      fxLayoutAlign="center center" class="mat-elevation-z0 features-card " >
      <div class="icon-style">
        <mat-icon>sell</mat-icon>
      </div>
      <h3 class="title text-primary text-align">Tag all assets</h3>
      <p class="content sub-title-mar10 text-accent text-align">List and tag
        all your desired assets with QR codes</p>
    </a>
    <a fxFlex="calc(33% - 40px)" fxFlex.lt-md="calc(50% - 40px)" fxFlex.lt-sm="calc(100% - 0px)" fxLayout="column"
      fxLayoutAlign="center center" class="mat-elevation-z0 features-card" >
      <div class="icon-style">
        <mat-icon>assignment</mat-icon>
      </div>
      <h3 class="title text-primary text-align">Raise tickets</h3>
      <p class="content sub-title-mar10 text-accent text-align">Scan QR code and raise tickets instantly</p>
    </a>
    <a fxFlex="calc(33% - 40px)" fxFlex.lt-md="calc(50% - 40px)" fxFlex.lt-sm="calc(100% - 0px)" fxLayout="column"
      fxLayoutAlign="center center" class="mat-elevation-z0 features-card" >
      <div class="icon-style">
        <mat-icon>miscellaneous_services</mat-icon>
      </div>
      <h3 class="title text-primary text-align">Automate monitoring of PM services</h3>
      <p class="content sub-title-mar10 text-accent text-align">Pre-load preventive maintenance schedules</p>
    </a>
    <a fxFlex="calc(33% - 40px)" fxFlex.lt-md="calc(50% - 40px)" fxFlex.lt-sm="calc(100% - 0px)" fxLayout="column"
      fxLayoutAlign="center center" class="mat-elevation-z0 features-card" >
      <div class="icon-style">
        <mat-icon>analytics</mat-icon>
      </div>
      <h3 class="title text-primary text-align">View reports</h3>
      <p class="content sub-title-mar10 text-accent text-align">Generate reports on the health of your assets</p>
    </a>
    <a fxFlex="calc(33% - 40px)" fxFlex.lt-md="calc(50% - 40px)" fxFlex.lt-sm="calc(100% - 0px)" fxLayout="column"
      fxLayoutAlign="center center" class="mat-elevation-z0 features-card" >
      <div class="icon-style">
        <mat-icon>auto_graph</mat-icon>
      </div>
      <h3 class="title text-primary text-align">Present Asset losses</h3>
      <p class="content sub-title-mar10 text-accent text-align">Put an end to theft, misuse and pilferage of assets
      </p>
    </a>
    <a fxFlex="calc(33% - 40px)" fxFlex.lt-md="calc(50% - 40px)" fxFlex.lt-sm="calc(100% - 0px)" fxLayout="column"
      fxLayoutAlign="center center" class="mat-elevation-z0 features-card">
      <div class="icon-style">
        <mat-icon>reduce_capacity</mat-icon>
      </div>
      <h3 class="title text-primary text-align">Reduce dependency</h3>
      <p class="content sub-title-mar10 text-accent text-align">Free-up your staff currently deployed for asset
        tracking</p>
    </a>
    <div></div>

  </div>
</div>
<div class="div-pad fms-bag" fxLayout="row wrap" fxLayoutAlign="space-between none" id="aboutus">
  <div fxFlex="" fxFlex.lt-sm="100%" fxLayoutAlign="start center" >
    <img src="../../../../assets/images/fms-tech.png" width="70%">
  </div>
  <div fxFlex="" fxFlex.lt-sm="100%" fxLayout="column" fxLayoutAlign="center center" >
    <h1 fxFlexAlign="end" fxFlexAlign.lt-sm="start" class="title text-primary text-right">360FMS</h1>
    <p class="sub-title sub-title-mar10 text-accent text-right">The future of building management is pro-active
      maintenance.With 360FMS
      you are equipped with preventive maintenance software that can inform you about the health of your equipments even
      miles away.</p>
    <p class="sub-title sub-title-mar10 text-accent text-right">Track maintenance costs, Schedule services, manage
      vendors and customize
      alerts all in one place. That helps you save costs and increase the lifespan of your assets</p>
  </div>
</div>
<div class="div-pad " fxLayout="row wrap" fxLayout.lt-sm="column-reverse" fxLayoutAlign="space-between none">
  <div fxFlex="" fxFlex.lt-sm="100%" fxLayout="column" fxLayoutAlign="center start" >
    <h1 class="title text-primary ">With 360FMS you can :</h1>
    <li class="sub-title sub-title-mar5 text-accent ">Prevent breakdowns and decrease cost of maintenance</li>
    <li class="sub-title sub-title-mar5 text-accent ">Reduce downtime by tracking response and resolution time</li>
    <li class="sub-title sub-title-mar5 text-accent ">Setup a preventive maintenance (PM) schedule and monitor it in
      real time</li>
    <li class="sub-title sub-title-mar5 text-accent ">Track employee attendance and performance in one platform</li>
    <li class="sub-title sub-title-mar5 text-accent ">Get customized MIS reports and analysis</li>
    <li class="sub-title sub-title-mar5 text-accent ">All the information in one transparent platform</li>
    <li class="sub-title sub-title-mar5 text-accent ">Improve the operational lifecycle of equipment</li>
    <li class="sub-title sub-title-mar5 text-accent ">Real-time tracking of maintenance issues</li>
    <li class="sub-title sub-title-mar5 text-accent ">Reports on equipment health</li>
    <li class="sub-title sub-title-mar5 text-accent ">Store important documents like : SLA's and protocols</li>
    <li class="sub-title sub-title-mar5 text-accent ">Reduce your workload</li>
    <li class="sub-title sub-title-mar5 text-accent ">All the building equipment are given a QR code</li>
    <li class="sub-title sub-title-mar5 text-accent ">Information about the equipment is available at a scan</li>
    <li class="sub-title sub-title-mar5 text-accent ">For raising a ticket, just scan the code</li>
    <li class="sub-title sub-title-mar5 text-accent ">Check the functioning of all the devices</li>
  </div>
  <div fxFlex="" fxFlex.lt-sm="100%" fxLayoutAlign="center end" >
    <img src="../../../../assets/images/with-fms.png" width="70%">
  </div>
</div>
<div class="div-pad" style="background-color: #e2ebff;" fxLayout="column"  id="ourTeam">
  <div fxLayout="column" fxLayoutAlign="center center" >
    <h1 class="title text-primary text-align">Meet Our Leadership Team</h1>
    <p class="sub-title sub-title-mar10 text-accent text-align">A futures
      contract allows a trader to speculate on the direction of movement of commodity's price</p>
  </div>
  <div class="div-sub-pad" fxLayout="row wrap" >
    <div fxFlex="33%" fxFlex.lt-sm="100%" fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 20px;">
      <img src="../../../../assets/images/amit.jpg" width="50%" style="border-radius:50%; border:3px solid #22ade3">
      <h3 class="title text-primary">Amit verma</h3>
      <p class="sub-title sub-title-mar10 text-accent text-align">Managing Director</p>
    </div>
    <div fxFlex="33%" fxFlex.lt-sm="100%" fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 20px;">
      <img src="../../../../assets/images/guruprasad.jpg" width="50%"
        style="border-radius:50%; border:3px solid #22ade3">
      <h3 class="title text-primary">Guruprasad Batni</h3>
      <p class="sub-title sub-title-mar10 text-accent text-align">Director - Business Development</p>

    </div>
    <div fxFlex="33%" fxFlex.lt-sm="100%" fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 20px;">
      <img src="../../../../assets/images/ramesh.jpg" width="50%" style="border-radius:50%; border:3px solid #22ade3">
      <h3 class="title text-primary">Ramesh Rao</h3>
      <p class="sub-title sub-title-mar10 text-accent text-align">Facilitator</p>

    </div>
  </div>
</div>
<div class="div-pad" style="background-color: white;" fxLayout="column" id="contactUs">
  <h1 class="title text-primary text-align">Contact Us</h1>
  <p class="sub-title sub-title-mar10 text-accent text-align">360FMS is an industry leader in HAVC maintenance services
    and has hands-on experience in delivering HAVC projects</p>
  <div fxLayout="row wrap" fxLayoutGap="50px" fxLayoutGap.lt-md="0px" class="div-sub-pad">
    <div fxFlex="calc(50% - 50px)" fxFlex.lt-md="100%" fxLayout="column" style="padding-bottom: 20px ;">
      <div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutGap.lt-sm="0px" style="margin-bottom: 30px;">

        <div fxFlex="calc(50% - 20px)" fxFlex.lt-sm="100%" fxLayout="column">
          <div fxLayout="column">
            <h4 class="title text-primary">Phone</h4>
            <p class="sub-title sub-title-mar5 text-accent">+91 9909909909</p>
          </div>
          <div fxLayout="column">
            <h4 class="title text-primary">Email</h4>
            <p class="sub-title sub-title-mar5 text-accent">360fms@services.org</p>
          </div>
        </div>
        <div fxFlex="calc(50% - 20px)" fxFlex.lt-sm="100%" style="margin-bottom: 20px !important;">
          <h4 class="title text-primary">Address</h4>
          <p class="sub-title sub-title-mar5 text-accent">#273, 2rd Floor, Jois Plaza
            Kathriguppe Signal, Outer Ring Road
            Banashankari 3rd Stage
            Bangalore - 560 085</p>
        </div>
        <div></div>
      </div>
      <div fxLayout="column" [formGroup]="contactForm">
        <h3 class="title text-primary">Send us message</h3>
        <div fxLayout="column">
          <p class="sub-title sub-title-mar5 text-accent">NAME</p>
          <mat-form-field appearance="none" class="accounts1">
            <input matInput placeholder="Enter name" formControlName="name" required>
            <mat-error *ngIf="contactForm.get('name').hasError('required')">
              Name required
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column">
          <p class="sub-title sub-title-mar5 text-accent">EMAIL ADDRESS</p>
          <mat-form-field appearance="none" class="accounts1">
            <input matInput placeholder="Enter email id" formControlName="email" type="email" autocomplete="email"
              inputmode="email" required>
            <mat-error *ngIf="contactForm.get('email').hasError('required')">
              Email required
            </mat-error>
            <mat-error
              *ngIf="!contactForm.get('email').hasError('required') && contactForm.get('email').hasError('email')">
              Enter valid email
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column">
          <p class="sub-title sub-title-mar5 text-accent">Phone Number</p>
          <mat-form-field appearance="none" class="accounts1">
            <input matInput placeholder="Enter phone number"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10"
              type="tel" autocomplete="tel" inputmode="tel" minlength="10" formControlName="phoneNumber" required>
            <mat-error *ngIf="contactForm.get('phoneNumber').hasError('required')">
              PhoneNumber required
            </mat-error>
            <mat-error
              *ngIf="!contactForm.get('phoneNumber').hasError('required') && contactForm.get('phoneNumber').hasError('pattern')">
              Enter valid phoneNumber
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column">
          <p class="sub-title sub-title-mar5 text-accent">MESSAGE</p>
          <mat-form-field appearance="none" class="accounts1">
            <input matInput placeholder="Enter message" formControlName="message" required>
            <mat-error *ngIf="contactForm.get('message').hasError('required')">
              message required
            </mat-error>
          </mat-form-field>
        </div>
        <p style="margin:0px;color:red" *ngIf="errorMessage">{{errorMessage}}</p>
        <div fxLayoutAlign="end end">
          <button mat-raised-button class="btn-border-radius" [disabled]="showSpinner" fxLayout="row nowrap"
            fxLayoutAlign="center center" fxLayoutGap="10px" style="color:whitesmoke; background-color: #fa8703;"
            (click)="onSubmit()">
            <div fxFlex="40px" *ngIf="showSpinner" fxLayoutAlign="center center">
              <mat-spinner></mat-spinner>
            </div>
            <span>{{showSpinner == true ?'Sending':'Send Message'}}</span>
          </button>
        </div>
      </div>
    </div>
    <iframe fxFlex="calc(50% - 50px)" fxFlex.lt-md="100%"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.734376955626!2d77.54841281430397!3d12.924786219402069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1563509f7627%3A0xc22138dba08ad58d!2sPromena%20Llp!5e0!3m2!1sen!2sin!4v1628579189606!5m2!1sen!2sin"
      style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
</div>