import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountsService } from './api/accounts.service';
import { ClientWebService } from './api/clientWeb.service';
import { CompaniesService } from './api/companies.service';
import { DropDownService } from './api/dropDown.service';
import { HomeService } from './api/home.service';
import { ManageAssetsService } from './api/manageAssets.service';
import { ManageDiscussionsService } from './api/manageDiscussions.service';
import { ManageSparesService } from './api/manageSpares.service';
import { ManageTechniciansService } from './api/manageTechnicians.service';
import { ManageTicketsService } from './api/manageTickets.service';
import { ManageUsersService } from './api/manageUsers.service';
import { SupervisorService } from './api/supervisor.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
