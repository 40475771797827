<ngx-loading-bar [color]="'#F16800'"></ngx-loading-bar>
<mat-sidenav-container class="main">
    <mat-sidenav #drawer class="sidenav mat-elevation-z11" fixedInViewport *ngIf="!specialPage && !landingPage"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
        <app-side-navigation></app-side-navigation>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar class="special-page-toolbar mat-elevation-z1" *ngIf="specialPage || landingPage" fxLayout="row"
            fxLayoutAlign="space-between center">
            <a [routerLink]="['/']" routerLinkActive="active">
                <img src="../assets/images/logo.png" width="200px" alt="logo" (click)="activateRoute(1)">
            </a>
            <div fxShow fxHide.lt-md fxLayout="row" fxLayoutGap="20px">
                <button mat-button (click)="activateRoute(1)">Home</button>
                <button mat-button (click)="activateRoute(2)">About Us</button>
                <button mat-button (click)="activateRoute(3)">Our Team</button>
                <button mat-button (click)="activateRoute(4)">Contact Us</button>
                <button mat-raised-button
                    style="background-image: linear-gradient(#05cafc, #066edd) !important; color:whitesmoke;border-radius: 30px;"
                    [routerLink]="['/account/sign-in']">Sign In</button>
            </div>
            <div fxShow fxHide.gt-sm>
                <button mat-icon-button aria-label="Example icon-button with a menu" aria-label="menu button"
                    (mouseenter)="mouseEnter(employerTrigger)" (mouseleave)="mouseLeave(employerTrigger)"
                    #employerTrigger="matMenuTrigger" [matMenuTriggerFor]="categorySubMenu">
                    <mat-icon color="primary" aria-label="menu icon">
                        {{employerTrigger.menuOpen ? 'close': 'menu'}}
                    </mat-icon>
                </button>
                <mat-menu #categorySubMenu="matMenu" [hasBackdrop]="false">
                    <span (mouseleave)="menuMouseLeave(employerTrigger)" (mouseenter)="trigger = 1">
                        <button mat-menu-item (click)="activateRoute(1)">Home</button>
                        <button mat-menu-item (click)="activateRoute(2)">About Us</button>
                        <button mat-menu-item (click)="activateRoute(3)">Our Team</button>
                        <button mat-menu-item (click)="activateRoute(4)">Contact Us</button>
                        <button mat-menu-item routerLink="/account/sign-in">Sign In</button>
                    </span>
                </mat-menu>
            </div>
        </mat-toolbar>
        <app-navigation *ngIf="!specialPage && !landingPage" (toggle)="drawer.toggle()" (toggleTheme)="toggleTheme()"
            (toggleUrl)="toggleUrl()"></app-navigation>
        <div [ngClass]="specialPage || landingPage ? 'outlet-100': 'outlet-90'"
            [@routeAnimations]="prepareRoute(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
        <app-footer *ngIf="landingPage"></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>