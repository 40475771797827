<div class="SideListCont">
    <div class="ilogo" fxLayoutAlign="center center">
        <img *ngIf="!miniMode" src="../../../../assets/images/new-new-new-logo.png" style="width:50%">
    </div>
    <mat-divider></mat-divider>
    <mat-accordion class="CustomSideNav" [ngClass]="[miniMode ? 'miniCustomNavBar' : 'normCustomNavBar']">
        <ng-container *ngFor="let item of menuItems">
            <ng-container [ngSwitch]="item.isExpandable">
                <mat-expansion-panel *ngSwitchCase="true" class="mat-elevation-z0"
                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                    <mat-expansion-panel-header [collapsedHeight]="'28px'" [expandedHeight]="'28px'">
                        <span class="linkHead material-icons">{{item.icon}}</span>
                        <span *ngIf="!miniMode" class="linkBody" style="font-weight: 400;"> {{item.title}} </span>
                    </mat-expansion-panel-header>
                    <mat-accordion class="CustomSideNav">
                        <ng-container *ngFor="let item1 of item.items">
                            <ng-container [ngSwitch]="item1.isExpandable">
                                <mat-expansion-panel *ngSwitchCase="true" class="mat-elevation-z0"
                                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                                    <mat-expansion-panel-header [collapsedHeight]="'28px'" [expandedHeight]="'28px'">
                                        <span class="linkHead material-icons">{{item1.icon}}</span>
                                        <span *ngIf="!miniMode" class="linkBody"> {{item1.title}} </span>
                                    </mat-expansion-panel-header>
                                    <mat-accordion class="CustomSideNav">
                                        <ng-container *ngFor="let item2 of item1.items">
                                            <ng-container [ngSwitch]="item2.isExpandable">
                                                <mat-expansion-panel *ngSwitchCase="true" class="mat-elevation-z0"
                                                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                                                    <mat-expansion-panel-header [collapsedHeight]="'28px'"
                                                        [expandedHeight]="'28px'">
                                                        <span class="linkHead material-icons">{{item2.icon}}</span>
                                                        <span *ngIf="!miniMode" class="linkBody"> {{item2.title}}
                                                        </span>
                                                    </mat-expansion-panel-header>
                                                    <mat-accordion class="CustomSideNav">
                                                        <ng-container *ngFor="let item3 of item2.items">
                                                            <ng-container [ngSwitch]="item3.isExpandable">
                                                                <mat-expansion-panel *ngSwitchCase="true"
                                                                    class="mat-elevation-z0"
                                                                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                                                                    <mat-expansion-panel-header
                                                                        [collapsedHeight]="'28px'"
                                                                        [expandedHeight]="'28px'">
                                                                        <span class="linkHead material-icons">{{item3.icon}}</span>
                                                                        <span *ngIf="!miniMode" class="linkBody">
                                                                            {{item3.title}} </span>
                                                                    </mat-expansion-panel-header>
                                                                    <mat-accordion class="CustomSideNav">
                                                                        <ng-container *ngFor="let item4 of item3.items">

                                                                        </ng-container>
                                                                    </mat-accordion>
                                                                </mat-expansion-panel>
                                                                <mat-nav-list *ngSwitchDefault class="headList"
                                                                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                                                                    <a mat-list-item
                                                                        routerLinkActive="activee mat-elevation-z11"
                                                                        [routerLink]="item3.link"><span
                                                                            class="linkHead material-icons">{{item3.icon}}</span>
                                                                        <span *ngIf="!miniMode"
                                                                            class="linkBody">{{item3.title}}</span>
                                                                    </a>
                                                                </mat-nav-list>
                                                            </ng-container>
                                                        </ng-container>
                                                    </mat-accordion>
                                                </mat-expansion-panel>
                                                <mat-nav-list *ngSwitchDefault class="headList"
                                                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                                                    <a mat-list-item routerLinkActive="activee mat-elevation-z11"
                                                        [routerLink]="item2.link"><span
                                                            class="linkHead material-icons">{{item2.icon}}</span>
                                                        <span *ngIf="!miniMode" class="linkBody">{{item2.title}}</span>
                                                    </a>
                                                </mat-nav-list>
                                            </ng-container>
                                        </ng-container>
                                    </mat-accordion>
                                </mat-expansion-panel>
                                <mat-nav-list *ngSwitchDefault class="headList"
                                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                                    <a mat-list-item routerLinkActive="activee mat-elevation-z11"
                                        [routerLink]="item1.link"><span class="linkHead material-icons"
                                            style="font-size: 16px !important;">{{item1.icon}}</span>
                                        <span *ngIf="!miniMode" class="linkBody">{{item1.title}}</span>
                                    </a>
                                </mat-nav-list>
                            </ng-container>
                        </ng-container>
                    </mat-accordion>
                </mat-expansion-panel>
                <mat-nav-list *ngSwitchDefault class="headList"
                    [ngClass]="[miniMode ? 'miniHeadList' : 'normHeadList']">
                    <a mat-list-item routerLinkActive="activee mat-elevation-z0" [routerLink]="item.link"><span
                            class="linkHead material-icons">{{item.icon}}</span>
                        <span *ngIf="!miniMode" class="linkBody">{{item.title}}</span>
                    </a>
                </mat-nav-list>
            </ng-container>
        </ng-container>
    </mat-accordion>
</div>