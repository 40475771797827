import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthGuard } from './guards/admin-auth.guard';
import { ClientAuthGuard } from './guards/client-auth.guard';
import { SupervisorAuthGuard } from './guards/supervisor-auth.guard';
import { HomeComponent } from './_modules/landing-pages/home/home.component';
import { PrivacyPolicyComponent } from './_modules/landing-pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './_modules/landing-pages/terms-conditions/terms-conditions.component';
import { NotificationsComponent } from './_modules/shared/notifications/notifications.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, data: { animation: 'HomePage' } },
  // { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { animation: 'PrivacyPolicyPage' } },
  { path: 'terms-conditions', component: TermsConditionsComponent, data: { animation: 'TermsConditionsPage' } },
  { path: 'account', loadChildren: () => import('src/app/_modules/account/account.module').then(m => m.AccountModule) },
  {
    path: 'admin',
    loadChildren: () => import('src/app/_modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'client',
    loadChildren: () => import('src/app/_modules/Client/client/client.module').then(m => m.ClientModule),
    canActivate: [ClientAuthGuard]
  },
  {
    path: 'supervisor',
    loadChildren: () => import('src/app/_modules/supervisor/supervisor.module').then(m => m.SupervisorModule),
    canActivate: [SupervisorAuthGuard]
  },
  { path: 'docs', redirectTo: '/account/docs', pathMatch: 'full' },
  { path: 'shared', loadChildren: () => import('src/app/_modules/shared/shared.module').then(m => m.SharedModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
