/**
 * Mavac API
 * API for Ticket management application
 *
 * The version of the OpenAPI document: admin
 * Contact: amithbharadwaj08@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AssignTicketModel } from '../model/models';
import { DisputedTicketModelPagedResponse } from '../model/models';
import { DownTimeModel } from '../model/models';
import { ReAssignTicketResponse } from '../model/models';
import { Ticket } from '../model/models';
import { TicketAssignment } from '../model/models';
import { TicketDetails } from '../model/models';
import { TicketReportModelListApiResponse } from '../model/models';
import { TicketReportsModelApiResponse } from '../model/models';
import { TicketSearchModel } from '../model/models';
import { TicketViewModelPagedResponse } from '../model/models';
import { TicketsModelResponsePagedResponse } from '../model/models';
import { TimeModel } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ManageTicketsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param assignTicketModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsAssignTicketPost(assignTicketModel?: AssignTicketModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TicketAssignment>;
    public apiManageTicketsAssignTicketPost(assignTicketModel?: AssignTicketModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TicketAssignment>>;
    public apiManageTicketsAssignTicketPost(assignTicketModel?: AssignTicketModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TicketAssignment>>;
    public apiManageTicketsAssignTicketPost(assignTicketModel?: AssignTicketModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TicketAssignment>(`${this.configuration.basePath}/api/ManageTickets/AssignTicket`,
            assignTicketModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ticketCode 
     * @param disputeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsDisputeTicketPost(ticketCode?: string, disputeId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReAssignTicketResponse>;
    public apiManageTicketsDisputeTicketPost(ticketCode?: string, disputeId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReAssignTicketResponse>>;
    public apiManageTicketsDisputeTicketPost(ticketCode?: string, disputeId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReAssignTicketResponse>>;
    public apiManageTicketsDisputeTicketPost(ticketCode?: string, disputeId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ticketCode !== undefined && ticketCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ticketCode, 'ticketCode');
        }
        if (disputeId !== undefined && disputeId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>disputeId, 'disputeId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ReAssignTicketResponse>(`${this.configuration.basePath}/api/ManageTickets/DisputeTicket`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param fromdate 
     * @param todate 
     * @param siteId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetAllAssetResolutiontimeBasedOnSitePost(fromdate?: string, todate?: string, siteId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public apiManageTicketsGetAllAssetResolutiontimeBasedOnSitePost(fromdate?: string, todate?: string, siteId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public apiManageTicketsGetAllAssetResolutiontimeBasedOnSitePost(fromdate?: string, todate?: string, siteId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public apiManageTicketsGetAllAssetResolutiontimeBasedOnSitePost(fromdate?: string, todate?: string, siteId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }
        if (siteId !== undefined && siteId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>siteId, 'siteId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/ManageTickets/GetAllAssetResolutiontimeBasedOnSite`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param fromdate 
     * @param todate 
     * @param pageindex 
     * @param pagesize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetAllAssetResolutiontimeGridPost(fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TimeModel>;
    public apiManageTicketsGetAllAssetResolutiontimeGridPost(fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TimeModel>>;
    public apiManageTicketsGetAllAssetResolutiontimeGridPost(fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TimeModel>>;
    public apiManageTicketsGetAllAssetResolutiontimeGridPost(fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }
        if (pageindex !== undefined && pageindex !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageindex, 'pageindex');
        }
        if (pagesize !== undefined && pagesize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pagesize, 'pagesize');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TimeModel>(`${this.configuration.basePath}/api/ManageTickets/GetAllAssetResolutiontimeGrid`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param fromdate 
     * @param todate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetAllAssetResolutiontimePost(fromdate?: string, todate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TimeModel>;
    public apiManageTicketsGetAllAssetResolutiontimePost(fromdate?: string, todate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TimeModel>>;
    public apiManageTicketsGetAllAssetResolutiontimePost(fromdate?: string, todate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TimeModel>>;
    public apiManageTicketsGetAllAssetResolutiontimePost(fromdate?: string, todate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TimeModel>(`${this.configuration.basePath}/api/ManageTickets/GetAllAssetResolutiontime`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param technicianId 
     * @param fromdate 
     * @param todate 
     * @param pageindex 
     * @param pagesize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetAllTechnicianResponsetimeGridPost(technicianId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TimeModel>;
    public apiManageTicketsGetAllTechnicianResponsetimeGridPost(technicianId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TimeModel>>;
    public apiManageTicketsGetAllTechnicianResponsetimeGridPost(technicianId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TimeModel>>;
    public apiManageTicketsGetAllTechnicianResponsetimeGridPost(technicianId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (technicianId !== undefined && technicianId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>technicianId, 'technicianId');
        }
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }
        if (pageindex !== undefined && pageindex !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageindex, 'pageindex');
        }
        if (pagesize !== undefined && pagesize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pagesize, 'pagesize');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TimeModel>(`${this.configuration.basePath}/api/ManageTickets/GetAllTechnicianResponsetimeGrid`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param technicianId 
     * @param fromdate 
     * @param todate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetAllTechnicianResponsetimePost(technicianId?: string, fromdate?: string, todate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TimeModel>;
    public apiManageTicketsGetAllTechnicianResponsetimePost(technicianId?: string, fromdate?: string, todate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TimeModel>>;
    public apiManageTicketsGetAllTechnicianResponsetimePost(technicianId?: string, fromdate?: string, todate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TimeModel>>;
    public apiManageTicketsGetAllTechnicianResponsetimePost(technicianId?: string, fromdate?: string, todate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (technicianId !== undefined && technicianId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>technicianId, 'technicianId');
        }
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TimeModel>(`${this.configuration.basePath}/api/ManageTickets/GetAllTechnicianResponsetime`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param assetId 
     * @param siteId 
     * @param fromdate 
     * @param todate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetAssetDowntimeBasedOnSitePost(assetId?: string, siteId?: string, fromdate?: string, todate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public apiManageTicketsGetAssetDowntimeBasedOnSitePost(assetId?: string, siteId?: string, fromdate?: string, todate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public apiManageTicketsGetAssetDowntimeBasedOnSitePost(assetId?: string, siteId?: string, fromdate?: string, todate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public apiManageTicketsGetAssetDowntimeBasedOnSitePost(assetId?: string, siteId?: string, fromdate?: string, todate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (assetId !== undefined && assetId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assetId, 'assetId');
        }
        if (siteId !== undefined && siteId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>siteId, 'siteId');
        }
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/ManageTickets/GetAssetDowntimeBasedOnSite`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param assetId 
     * @param fromdate 
     * @param todate 
     * @param pageindex 
     * @param pagesize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetAssetDowntimeGridPost(assetId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DownTimeModel>;
    public apiManageTicketsGetAssetDowntimeGridPost(assetId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DownTimeModel>>;
    public apiManageTicketsGetAssetDowntimeGridPost(assetId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DownTimeModel>>;
    public apiManageTicketsGetAssetDowntimeGridPost(assetId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (assetId !== undefined && assetId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assetId, 'assetId');
        }
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }
        if (pageindex !== undefined && pageindex !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageindex, 'pageindex');
        }
        if (pagesize !== undefined && pagesize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pagesize, 'pagesize');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DownTimeModel>(`${this.configuration.basePath}/api/ManageTickets/GetAssetDowntimeGrid`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param assetId 
     * @param fromdate 
     * @param todate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetAssetDowntimePost(assetId?: string, fromdate?: string, todate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DownTimeModel>;
    public apiManageTicketsGetAssetDowntimePost(assetId?: string, fromdate?: string, todate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DownTimeModel>>;
    public apiManageTicketsGetAssetDowntimePost(assetId?: string, fromdate?: string, todate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DownTimeModel>>;
    public apiManageTicketsGetAssetDowntimePost(assetId?: string, fromdate?: string, todate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (assetId !== undefined && assetId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assetId, 'assetId');
        }
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DownTimeModel>(`${this.configuration.basePath}/api/ManageTickets/GetAssetDowntime`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param siteId 
     * @param companyId 
     * @param pageNumber 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetDisputedTicketsGet(siteId?: string, companyId?: string, pageNumber?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DisputedTicketModelPagedResponse>;
    public apiManageTicketsGetDisputedTicketsGet(siteId?: string, companyId?: string, pageNumber?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DisputedTicketModelPagedResponse>>;
    public apiManageTicketsGetDisputedTicketsGet(siteId?: string, companyId?: string, pageNumber?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DisputedTicketModelPagedResponse>>;
    public apiManageTicketsGetDisputedTicketsGet(siteId?: string, companyId?: string, pageNumber?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (siteId !== undefined && siteId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>siteId, 'siteId');
        }
        if (companyId !== undefined && companyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyId, 'companyId');
        }
        if (pageNumber !== undefined && pageNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageNumber, 'pageNumber');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DisputedTicketModelPagedResponse>(`${this.configuration.basePath}/api/ManageTickets/GetDisputedTickets`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param assetId 
     * @param fromdate 
     * @param todate 
     * @param pageindex 
     * @param pagesize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetIndividualAssetResolutiontimeGridPost(assetId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TimeModel>;
    public apiManageTicketsGetIndividualAssetResolutiontimeGridPost(assetId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TimeModel>>;
    public apiManageTicketsGetIndividualAssetResolutiontimeGridPost(assetId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TimeModel>>;
    public apiManageTicketsGetIndividualAssetResolutiontimeGridPost(assetId?: string, fromdate?: string, todate?: string, pageindex?: number, pagesize?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (assetId !== undefined && assetId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assetId, 'assetId');
        }
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }
        if (pageindex !== undefined && pageindex !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageindex, 'pageindex');
        }
        if (pagesize !== undefined && pagesize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pagesize, 'pagesize');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TimeModel>(`${this.configuration.basePath}/api/ManageTickets/GetIndividualAssetResolutiontimeGrid`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param assetId 
     * @param fromdate 
     * @param todate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetIndividualAssetResolutiontimePost(assetId?: string, fromdate?: string, todate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TimeModel>;
    public apiManageTicketsGetIndividualAssetResolutiontimePost(assetId?: string, fromdate?: string, todate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TimeModel>>;
    public apiManageTicketsGetIndividualAssetResolutiontimePost(assetId?: string, fromdate?: string, todate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TimeModel>>;
    public apiManageTicketsGetIndividualAssetResolutiontimePost(assetId?: string, fromdate?: string, todate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (assetId !== undefined && assetId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assetId, 'assetId');
        }
        if (fromdate !== undefined && fromdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromdate, 'fromdate');
        }
        if (todate !== undefined && todate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>todate, 'todate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TimeModel>(`${this.configuration.basePath}/api/ManageTickets/GetIndividualAssetResolutiontime`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetTicketDetailsIdGet(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TicketDetails>;
    public apiManageTicketsGetTicketDetailsIdGet(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TicketDetails>>;
    public apiManageTicketsGetTicketDetailsIdGet(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TicketDetails>>;
    public apiManageTicketsGetTicketDetailsIdGet(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiManageTicketsGetTicketDetailsIdGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<TicketDetails>(`${this.configuration.basePath}/api/ManageTickets/GetTicketDetails/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetTicketIdGet(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Ticket>;
    public apiManageTicketsGetTicketIdGet(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Ticket>>;
    public apiManageTicketsGetTicketIdGet(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Ticket>>;
    public apiManageTicketsGetTicketIdGet(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiManageTicketsGetTicketIdGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Ticket>(`${this.configuration.basePath}/api/ManageTickets/GetTicket/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ticketid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetTicketReportsGet(ticketid?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TicketReportModelListApiResponse>;
    public apiManageTicketsGetTicketReportsGet(ticketid?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TicketReportModelListApiResponse>>;
    public apiManageTicketsGetTicketReportsGet(ticketid?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TicketReportModelListApiResponse>>;
    public apiManageTicketsGetTicketReportsGet(ticketid?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ticketid !== undefined && ticketid !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ticketid, 'ticketid');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<TicketReportModelListApiResponse>(`${this.configuration.basePath}/api/ManageTickets/GetTicketReports`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ticketSearchModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetTicketsPost(ticketSearchModel?: TicketSearchModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TicketsModelResponsePagedResponse>;
    public apiManageTicketsGetTicketsPost(ticketSearchModel?: TicketSearchModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TicketsModelResponsePagedResponse>>;
    public apiManageTicketsGetTicketsPost(ticketSearchModel?: TicketSearchModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TicketsModelResponsePagedResponse>>;
    public apiManageTicketsGetTicketsPost(ticketSearchModel?: TicketSearchModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TicketsModelResponsePagedResponse>(`${this.configuration.basePath}/api/ManageTickets/GetTickets`,
            ticketSearchModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ticketid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetTicketsReportsGet(ticketid?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TicketReportsModelApiResponse>;
    public apiManageTicketsGetTicketsReportsGet(ticketid?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TicketReportsModelApiResponse>>;
    public apiManageTicketsGetTicketsReportsGet(ticketid?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TicketReportsModelApiResponse>>;
    public apiManageTicketsGetTicketsReportsGet(ticketid?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ticketid !== undefined && ticketid !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ticketid, 'ticketid');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<TicketReportsModelApiResponse>(`${this.configuration.basePath}/api/ManageTickets/GetTicketsReports`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pageIndex 
     * @param pageSize 
     * @param searchStr 
     * @param siteID 
     * @param technicianID 
     * @param companyID 
     * @param fromDate 
     * @param toDate 
     * @param status 
     * @param priority 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsGetTicketsSearchGet(pageIndex?: number, pageSize?: number, searchStr?: string, siteID?: string, technicianID?: string, companyID?: string, fromDate?: string, toDate?: string, status?: number, priority?: number, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TicketViewModelPagedResponse>;
    public apiManageTicketsGetTicketsSearchGet(pageIndex?: number, pageSize?: number, searchStr?: string, siteID?: string, technicianID?: string, companyID?: string, fromDate?: string, toDate?: string, status?: number, priority?: number, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TicketViewModelPagedResponse>>;
    public apiManageTicketsGetTicketsSearchGet(pageIndex?: number, pageSize?: number, searchStr?: string, siteID?: string, technicianID?: string, companyID?: string, fromDate?: string, toDate?: string, status?: number, priority?: number, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TicketViewModelPagedResponse>>;
    public apiManageTicketsGetTicketsSearchGet(pageIndex?: number, pageSize?: number, searchStr?: string, siteID?: string, technicianID?: string, companyID?: string, fromDate?: string, toDate?: string, status?: number, priority?: number, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageIndex !== undefined && pageIndex !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageIndex, 'pageIndex');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }
        if (searchStr !== undefined && searchStr !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchStr, 'searchStr');
        }
        if (siteID !== undefined && siteID !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>siteID, 'siteID');
        }
        if (technicianID !== undefined && technicianID !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>technicianID, 'technicianID');
        }
        if (companyID !== undefined && companyID !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyID, 'companyID');
        }
        if (fromDate !== undefined && fromDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromDate, 'fromDate');
        }
        if (toDate !== undefined && toDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>toDate, 'toDate');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (priority !== undefined && priority !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>priority, 'priority');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<TicketViewModelPagedResponse>(`${this.configuration.basePath}/api/ManageTickets/GetTicketsSearch`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param assignTicketModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManageTicketsReAssignTicketPost(assignTicketModel?: AssignTicketModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TicketAssignment>;
    public apiManageTicketsReAssignTicketPost(assignTicketModel?: AssignTicketModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TicketAssignment>>;
    public apiManageTicketsReAssignTicketPost(assignTicketModel?: AssignTicketModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TicketAssignment>>;
    public apiManageTicketsReAssignTicketPost(assignTicketModel?: AssignTicketModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TicketAssignment>(`${this.configuration.basePath}/api/ManageTickets/ReAssignTicket`,
            assignTicketModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
