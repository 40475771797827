import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  userName = '';
  @Output() toggle = new EventEmitter();
  @Output() toggleTheme = new EventEmitter();
  @Output() toggleUrl = new EventEmitter();

  constructor(
    private location: Location,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userName = localStorage.getItem('name');
  }
  notificationRoute() {
    if (this.authService.isAdminLogin) {
      this.router.navigateByUrl('/admin/notifications');
    }
    else if (this.authService.isClientLogin) {
      this.router.navigateByUrl('/client/notifications');
    }
    else if (this.authService.isSupervisorLogin) {
      this.router.navigateByUrl('/supervisor/notifications');
    }
  }

  logOut(): void {
    this.authService.logout();
  }

  goBack(): void {
    this.location.back();
  }
}
