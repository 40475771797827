import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.search('/api/account/sign-in') > -1 || request.url.search('/api/Accounts/RefreshToken') > -1) {
            return next.handle(request);
        }
        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((error as HttpErrorResponse).status) {
                        case 401:
                            return this.handle401Error(request, next);
                        default:
                            return throwError(error);
                    }
                } else {
                    Observable.throw(error);
                }
            })
        );
    }

    /**
     * Adds authorization header with jwt token if not empty
     *
     * @param {HttpRequest<any>} req
     * @param {string} token
     * @returns {HttpRequest<any>}
     */
    private addTokenToRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token) {
            return req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        return req;
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);
            next.handle(req);
            // return this.accountService.refreshToken().pipe(
            //     switchMap((newToken: string) => {
            //         if (newToken) {
            //             this.tokenSubject.next(newToken);
            //             return next.handle(this.addTokenToRequest(req, newToken));
            //         }
            //         // If we don't get a new token, we are in trouble so logout.
            //         return this.logoutUser();
            //     }),
            //     catchError(error => {
            //         console.error("Unable to refresh token, error:", error);
            //         // If there is an exception calling 'refreshToken', bad news so logout.
            //         return this.logoutUser();
            //     }),
            //     finalize(() => {
            //         this.isRefreshingToken = false;
            //     })
            // )
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addTokenToRequest(req, token));
                })
            );

        }
    }
}
