import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration } from 'build/openapi';
import { BehaviorSubject } from 'rxjs';
import { constant } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userImage: string = '';
  public userName: string = '';
  public userEmail: string = '';
  public userGender: string = '';
  private userDetails: UserDetails;
  private clientLogin: boolean = false;
  private adminLogin: boolean = false;
  private supervisorLogin: boolean = false;
  private _loginListener = new BehaviorSubject<boolean>(false);
  loginListner$ = this._loginListener.asObservable();
  private _userDetails = new BehaviorSubject<UserDetails>(undefined);
  userDetailsListner$ = this._userDetails.asObservable();

  get isClientLogin() {
    return this.clientLogin;
  }
  get isAdminLogin() {
    return this.adminLogin;
  }
  get isSupervisorLogin() {
    return this.supervisorLogin;
  }

  constructor(private router: Router, private configuration: Configuration) { }

  // public refreshToken(): Observable<string> {
  //   return this.tokenService.tokenRefreshCreate({refresh: this.storage.getItem(constant.authData.refresh_token)}).pipe(
  //     map(res => {
  //       this.configuration.apiKeys['Bearer'] = `bearer ${res.refresh}`;
  //       let accessToken = res.refresh;
  //       let refreshToken = res.refresh;
  //       this.storage.setItem(constant.authData.access_token, accessToken);
  //       this.storage.setItem(constant.authData.refresh_token, refreshToken);

  //       this.clientLogin = this.checkUserRole(constant.roles.ROLE_CLIENT);
  //       this.adminLogin = this.che ckUserRole(constant.roles.ROLE_ADMIN);
  //       this.checkLogin();
  //       return accessToken;
  //     })
  //   );
  // }

  checkLogin() {
    if (this.storage.getItem(constant.authData.access_token)) {
      try {
        const data = this.parseJwt(this.storage.getItem(constant.authData.access_token));
        var Role = this.storage.getItem("roles").replace("[", "").replace("]", "").replace(",", "").replace('"', '').replace('"', '');
        this.adminLogin = Role === constant.roles.ROLE_ADMIN;
        this.clientLogin = Role === constant.roles.ROLE_CLIENT;
        this.supervisorLogin = Role === constant.roles.ROLE_SUPERVISOR;
        this.supervisorLogin = Role === constant.roles.ROLE_SUPERVISORS;
        this.userName = this.storage.getItem(constant.mainAccount.account_name);
        this.userGender = this.storage.getItem(constant.mainAccount.account_gender);
        this.userImage = this.storage.getItem(constant.mainAccount.photo_path);
        this.configuration.apiKeys['Bearer'] = `Bearer ${this.storage.getItem(constant.authData.access_token)}`;

        // Admin because of spelling in admin is not correct smaller Case letter
        this.configuration.apiKeys['bearer'] = `Bearer ${this.storage.getItem(constant.authData.access_token)}`;

        this._loginListener.next(true);
        if (this.userDetails) {
          this._userDetails.next(this.userDetails);
        } else {
          this.emitUserDetails();
        }
      } catch (error) {
        this.userDetails = undefined;
        this._loginListener.next(false);
        this._userDetails.next(this.userDetails);
      }
    } else {
      this.userDetails = undefined;
      this._loginListener.next(false);
      this._userDetails.next(this.userDetails);
    }
  }
  public emitImage(base64: string) {
    this.userImage = base64;
    this.userDetails = { userEmail: this.userEmail, userFullName: this.userName, userImageUrl: base64, userGender: this.userGender };
    this._userDetails.next(this.userDetails);
  }
  private emitUserDetails(): void {
    // if (this.isPatientLogin) {
    //   this.patientProfileService.apiPatientsProfileGet().subscribe(data => {
    //     this.userImage = data.patient.photoPath;
    //     this.userEmail = data.patient.email;
    //     this.userGender = data.patient.gender;
    //     this.userName = `${data.patient.firstName} ${data.patient.middleName ? data.patient.middleName : ''} ${data.patient.lastName}`;
    //     this.userDetails = { userEmail: data.patient.email, userFullName: this.userName,
    //     userImageUrl: data.patient.photoPath, userGender: data.patient.gender,
    //   temporaryAccess: this.isTemporaryAccess
  }
  //     this._userDetails.next(this.userDetails);
  //   });
  // }
  // else if (this.isPhysicianLogin) {
  //   this.physicianProfileService.apiPhysiciansGetProfileGet().subscribe(data => {
  //     this.userDetails = { userEmail: data.physician.email, 
  // userFullName: `${data.physician.firstName} ${data.physician.middleName} ${data.physician.lastName}`,
  //  userImageUrl: data.physician.photo, userGender: data.physician.gender, temporaryAccess: this.isTemporaryAccess };
  // this._userDetails.next(this.userDetails);
  //   });
  // }
  // }

  private parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const res = JSON.parse(base64) as UserToken;
    return res;
  }

  private checkUserRole(role: string): boolean {
    const token = this.storage.getItem(constant.authData.access_token);
    if (!token) {
      return false;
    }
    const roles = this.parseJwt(token).roles;
    return roles && roles.includes(role);
  }


  get storage(): Storage {
    if (localStorage.getItem('rememberMe')) {
      return localStorage;
    } else {
      return sessionStorage;
    }
  }
  public logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.storage.clear();
    this.clientLogin = false;
    this.adminLogin = false;
    this._loginListener.next(false);
    this.router.navigate(['/account/sign-in']);
  }

}
export interface UserDetails {
  userImageUrl: string;
  userEmail: string;
  userFullName: string;
  userGender: string;
}
export class UserToken {
  roles: string;
}
