export * from './accounts.service';
import { AccountsService } from './accounts.service';
export * from './clientWeb.service';
import { ClientWebService } from './clientWeb.service';
export * from './companies.service';
import { CompaniesService } from './companies.service';
export * from './dropDown.service';
import { DropDownService } from './dropDown.service';
export * from './home.service';
import { HomeService } from './home.service';
export * from './manageAssets.service';
import { ManageAssetsService } from './manageAssets.service';
export * from './manageDiscussions.service';
import { ManageDiscussionsService } from './manageDiscussions.service';
export * from './manageSpares.service';
import { ManageSparesService } from './manageSpares.service';
export * from './manageTechnicians.service';
import { ManageTechniciansService } from './manageTechnicians.service';
export * from './manageTickets.service';
import { ManageTicketsService } from './manageTickets.service';
export * from './manageUsers.service';
import { ManageUsersService } from './manageUsers.service';
export * from './supervisor.service';
import { SupervisorService } from './supervisor.service';
export const APIS = [AccountsService, ClientWebService, CompaniesService, DropDownService, HomeService, ManageAssetsService, ManageDiscussionsService, ManageSparesService, ManageTechniciansService, ManageTicketsService, ManageUsersService, SupervisorService];
