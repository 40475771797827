import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PopupSuccessComponent } from '../../shared/popup-success/popup-success.component';
import * as AOS from 'aos';
import { HomeService } from 'build/openapi';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  contactForm: FormGroup;
  errorMessage: string;
  showSpinner: boolean;

  constructor(
    private fb: FormBuilder,
    private homeService: HomeService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    AOS.init();
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[6-9]{1}[0-9]{9}$')]],
      message: ['', Validators.required],
    });
    this.contactForm.valueChanges.subscribe(val => {
      if (val) {
        if (this.contactForm.valid) {
          this.errorMessage = '';
        }
      }
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.showSpinner = true;
      this.homeService.apiHomeContactUsPost(this.contactForm.value).subscribe(res => {
        this.showSpinner = false;
        if (res.code === 1) {
          this.contactForm.reset();
          const timeout = 2000;
          const dialogRef = this.dialog.open(PopupSuccessComponent, {
            width: '300px',
            data: res.message
          });
          dialogRef.afterOpened().subscribe(_ => {
            setTimeout(() => {
              dialogRef.close();
            }, timeout);
          });
        } else {
          this.errorMessage = res.message;
        }
      }, (error: HttpErrorResponse) => {
        this.showSpinner = false;
        this.errorMessage = error.message;
      });
    } else {
      this.errorMessage = 'Please enter all required fields';
    }
  }

  activateRoute() {
    document.getElementById('contactUs').scrollIntoView({ behavior: 'smooth' });
  }
}
